import { Button, Grid, Paper } from "@mui/material";

export default function TutorialInfoOverlay({ children, showed, onClickOk, last }) {
    if(!showed) {
        return <></>
    }

    return (
        // <Grid
        //         contianer
        //         direction="row"
        //         justifyContent="space-around"
        //         alignItems="stretch">

            <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    className="overlayGreen"
                    style={{ minHeight: "100dvh", backgroundColor: "#E5FFEA" }}>
                <Grid item>
                    <span className="tutorial_overlay_content">
                        { children }
                    </span>
                </Grid>

                <Grid item>
                    <Button variant="contained" onClick={onClickOk} color="secondary">{ last ? "OK" : "PROSEGUI" }</Button>
                </Grid>
            </Grid>

        // </Grid>
    )
}
