import { Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import fetchAPI from "../utils/Fetcher"

export default function Welcome({ setIntroStatus }) {
    const [details, setDetails] = useState(false)
    const [accepted, setAccepted] = useState(false)

    function WelcomeNoDetails() {
        return (
            <>
                
                <Grid item>
                    <Typography variant="h1" className="mainTitle">
                        <span style={{
                            fontWeight: 400
                        }}>
                            CIAO
                        </span>
                    </Typography>
                </Grid>

                <Grid item>
                    <p>Grazie per aver scelto di partecipare a questo test. Il tuo contributo è fondamentale per il successo della nostra ricerca.</p>
                </Grid>

                <Grid item>
                    <Button variant="contained" onClick={() => setAccepted(true)}>INIZIAMO</Button>
                </Grid>

                <Grid item marginTop="4em">
                    <p className="fakeLink" onClick={() => setDetails(true)}>Il progetto</p>
                </Grid>
            </>
        );
    }

    function WelcomeDetails() {
        return (
            <>
                
                <Grid item>
                    <Typography variant="h3" className="mainTitle">
                        <span style={{
                            fontWeight: 400
                        }}>
                            IL PROGETTO
                        </span>
                    </Typography>
                </Grid>

                <Grid item>
                    <p>
                        Nell'ambito del laboratorio congiunto "Tecnologie digitali per l’identità, la tracciabilità e l’anticontraffazione" tra la Fondazione Bruno Kessler e l'Istituto Poligrafico e Zecca dello Stato, stiamo lavorando al progetto "Internet Voting", che mira a progettare e testare sistemi di voto via internet (i-voting) sicuri e facili da usare.
                    </p>
                    <p>
                        Per proteggere il sistema di voto da attacchi informatici, abbiamo pensato di integrare specifici meccanismi di sicurezza. Uno di questi prevede un controllo visivo di alcune informazioni da parte degli utenti. Questo test mira a valutare sperimentalmente l'usabilità dei diversi metodi di rappresentazione di queste informazioni, cercando di trovare il giusto equilibrio tra usabilità e sicurezza.
                    </p>

                    <p><a href="https://i-voting.fbk.eu/privacy" about="_blank">Informativa privacy</a></p>
                </Grid>

                <Grid item>
                    <div>Contatti:</div>
                    <div><a href="mailto:vsec@fbk.eu">vsec@fbk.eu</a></div>
                </Grid>

                <br />

                <Grid item>
                    <Button variant="contained" onClick={() => setDetails(false)}>HOMEPAGE</Button>
                </Grid>
            </>
        );
    }

    function WelcomePrivacy() {
        return (
            <>
                <Grid item>
                    <p>Prima di iniziare ti chiediamo qualche domanda su anagrafica, vista e attenzione che ci aiuterà a catalogare i dati raccolti.</p>
                    <br />
                    <p>I dati saranno trattati in modo anonimo, <a href="/privacy.html" target="_blank">qui puoi trovare l’informativa privacy completa</a>.</p>
                    <p>Proseguendo dichiari di averne preso visione.</p>
                </Grid>

                <Grid item>
                    <Button variant="contained" onClick={() => {
                        fetchAPI('/intro/start')
                        setIntroStatus("age")
                    }}>
                        PROSEGUI
                    </Button>
                </Grid>
            </>
        )
    }

    if(accepted) {
        return <WelcomePrivacy />
    }

    return (
        <>
            { !details && <WelcomeNoDetails /> }
            { details && <WelcomeDetails /> }
        </>
    );
}

