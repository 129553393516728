import { Button, Grid } from "@mui/material";
import Overlay from "../components/Overlay";

export default function LevelInfoOverlay({ type, open, onClose }) {
    return (
        <Overlay className="overlay" open={open} showClose={true} onClose={onClose} title="ISTRUZIONI">
            <h1>I Livelli</h1>
            <p>Il test  ha 6 livelli obbligatori, seguiti da livelli bonus opzionali.</p>
            <p>In ognuno dovrai indicare una tua preferenza tra <strong>2</strong> opzioni accompagnate da immagini o tra <strong>4</strong> opzioni senza immagini.</p><h2>Livelli obbligatori:</h2>
            <ul>
            <li><strong>Livelli 1 e 2:</strong>
            <ul>
            <li>Una singola domanda di preferenza per ognuno.</li>
            </ul>
            </li>
            <li><strong>Livelli 3 e 4:</strong>
            <ul>
            <li>La prima domanda ti chiederà una preferenza;</li>
            <li>La seconda ti richiederà di risolvere una somma di due numeri a una cifra e selezionare il risultato corretto.</li>
            </ul>
            </li>
            <li><strong>Livelli 5 e 6:</strong>
            <ul>
            <li>La prima domanda ti chiede una preferenza tra le opzioni disponibili;</li>
            <li>La seconda domanda ti chiederà il risultato di una somma tra due numeri a due cifre.</li>
            </ul>
            </li>
            </ul>
            <h2>Livelli bonus</h2>
            <ul>
            <li>Dopo aver completato i 6 livelli obbligatori, potrai fare dei livelli bonus (fino a 10 livelli bonus, per un totale di massimo 16 livelli in tutto);</li>
            <li>Completando correttamente i livelli bonus potrai accumulare punti extra e scalare la classifica;</li>
            <li>I livelli bonus hanno la stessa struttura dei livelli 5 e 6.</li>
            </ul>
            <br />
            <h1>Domande e conferme</h1>
            <ul>
            <li>Dopo aver risposto a ciascuna domanda, una volta premuto su <strong>Prosegui</strong>, visualizzerai una schermata di <strong>conferma</strong> della tua scelta. A questo punto, non sarà possibile modificare la tua risposta.</li>
            <li>Al termine di ogni livello, ti verrà mostrata una schermata di <strong>riepilogo</strong> con tutte le risposte date.</li>
            </ul>
            <br />
            <h1>Gli attacchi</h1>
            <p>Il test simula un quiz durante il quale potrebbero esserci degli attacchi informatici.</p>
            <p>C’è un meccanismo di sicurezza che dovrebbe aiutarti a rilevare gli attacchi in corso. Questo meccanismo mostra in alto all’interno della schermata un <strong>nuovo set di { type === 'emojis' ? 'emoji' : 'vocaboli' } casuali ogni volta che rispondi a una domanda</strong> del test (in alto a sinistra dopo le preferenze e in alto a destra dopo le somme).</p>
            <p>Se uno di questi set cambia prima che finisca il livello, vuol dire che c’è un attacco.</p>
            <p>Finito il livello puoi dimenticarti questi set: <strong>nel livello successivo ne appariranno di diversi</strong>.</p>
            <br />
            <p>Non tutti gli attacchi vengono rilevati da questo meccanismo.</p>
            <h2>Altre anomalie che indicano un attacco in corso</h2>
            <ul>
            <li>Le opzioni di risposta visualizzate non sono coerenti con la domanda;</li>
            <li>L’opzione selezionata e la risposta mostrata nella schermata di conferma e/o nella schermata di riepilogo non corrispondono;</li>
            <li>Non è presente il risultato corretto per la somma tra le opzioni disponibili.</li>
            </ul>
            <p>Alcuni errori potrebbero non presentarsi nel corso del test.</p>
            <br />
            <h2>Segnalazione</h2>
            <p><strong>Se rilevi un attacco informatico</strong> (sia grazie al meccanismo che per un’anomalia), <strong>ti chiediamo di segnalarlo</strong> con l’apposito pulsante.</p>
            <br />
            <p>Le segnalazioni completano il livello in corso, potrai proseguire con il livello successivo.</p>
            <br />
            <h2>Punti</h2>
            <p>Per ottenere i punti dei livelli bonus dovrai risolvere <em>correttamente</em> le somme e <em>segnalare subito</em> i problemi che riscontri.</p>
            <br />
            <h1>Sondaggio conclusivo</h1>
            <p><strong>Quando non vuoi fare altri livelli bonus</strong>, oppure quando li hai finiti tutti, usa l’apposito pulsante per passare al <strong>sondaggio conclusivo</strong>.
            <p>Ti sarà chiesto quanti e quali attacchi hai segnalato.</p>
            <p>Una volta passati al sondaggio non si può tornare indietro a fare altri livelli.</p></p>

            <Grid item>
                <Button variant="outlined" onClick={onClose}>Chiudi</Button>
            </Grid>
        </Overlay>
    )
}