import EcnodingView from "./EncodingView";
import ButtonsViews from "./ButtonsView";
import QuestionSubView from "./QuestionSubViews";
import TutorialInfoButton from "./TutorialInfoButton";
import TutorialInfoOverlay from "./TutorialInfoOverlay";



export default function DemoQuestion() {

    return (
        <>
            <header>
                <EcnodingView type="words" content={ {left: ["a", "b", "c", "d"], 
                                                      right: ["1", "2", "3", "4"]} } 
                    enable_left_highlighted="#008F1D" enable_right_highlighted="#FB4437" />
            </header>

            <TutorialInfoOverlay showed={false}>
                CIAO!
            </TutorialInfoOverlay>

            <section>
                <TutorialInfoButton />

                <QuestionSubView 
                    type="text"
                    question="Hey ciao?"
                    answares={[ {value: "ciao", answare: "ciao", highlighted: "blue"} ]}
                    onClick={e => console.log("Selected" + e)} />
            </section>

            <footer>

                {/* <ButtonsViews
                    enable_report={true} 
                    highlight_report={false} 
                    onReport={() => console.log("report")} 
                    allow_report={true}

                    enable_submit={true} 
                    highlight_submit={false} 
                    submit_text={"INVIA"} 
                    onSubmit={() => console.log("invia")} 
                    allow_submit={true}

                    enable_info={true} 
                    highlight_info={false}
                    onInfo={() => console.log("info")}


                    enable_steps={false} 
                    steps_current={null} 
                    steps_total={null}
                    />  */}

                <ButtonsViews
                    enable_report={true} 
                    highlight_report={"#008F1D"} 
                    onReport={() => console.log("report")} 
                    allow_report={false}

                    enable_submit={true} 
                    highlight_submit={"#FB4437"} 
                    submit_text={"INVIA"} 
                    onSubmit={() => console.log("invia")} 
                    allow_submit={false}

                    enable_info={true} 
                    highlight_info={"yellow"}
                    onInfo={() => console.log("info")}


                    enable_steps={true} 
                    steps_current={5} 
                    steps_total={10}
                    /> 
            </footer>
        </>
    )
}