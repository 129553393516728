import { useEffect, useState } from "react";
import fetchAPI from "../utils/Fetcher"
import { Button, Typography } from "@mui/material";
import QuestionView from "../views/QuestionView";
import SummaryView from "../views/SummaryView";
import LevelRankView from "../views/LevelRankView";
import LevelView from "../views/LevelView";

function sampleEncoding2(type, wrong=false) {
    if(type === "emojis") {
        return { left: (!wrong ? "⛔📪🐦🧺🍀" : "👰✊😷🧉😚") }
    } else {
        return { left: (!wrong ? ['trattato', 'incrocio', 'avere', 'satollo'] : ['lavagna', 'abaco', 'emotivo', 'ferro']) }
    }
}

function sampleEncoding1(type, full=false) {
    if(type === "emojis") {
        return { left: "👀🎉🌉👘🥏", right: (full ? '🍪🆖👛🌚🏦' : undefined) }
    } else {
        return { left: ['europa', 'opposto', 'appunto', 'dormire'], right: (full ? ['ribadire', 'prudente', 'ammenda', 'frollino'] : undefined) }
    }
}

function Intro({ setStatus }) {
    return (
        <LevelView 
                enable_encoding={false}
                enable_tutorial_button={true}
                enable_tutorial_overlay={true}
                tutorial_overlay_content={[
                    <>
                    <Typography variant="h3">Tutorial</Typography>
                    <p>Il test è diviso in <strong>livelli</strong>, in alcuni sarà <strong>simulato un attacco informatico</strong>.</p>
                    <p>Dovrai rispondere a delle domande e <strong>segnalare</strong> quando ti accorgi che c’è un attacco.</p>
                    <p>Completando con successo i livelli accumulerai punti e scalerai una <strong>classifica</strong> (anonimizzata).</p>
                    <br />
                    <p><strong>Vediamo ora come funziona un livello</strong></p>
                    </>
                ]}
                type="emojis"

                enable_report={false}
                enable_submit={false}
                enable_info={false}
                enable_survey={false}

                onOverlayOk={() => setStatus("level1")}
                >


        </LevelView>
    )

    // return (
    //     <>
    //         <section style={{ backgroundColor: "#E5FFEA", padding: "1em", height: "110svh" }}>
    //             <Typography variant="h3">Tutorial</Typography>

    //             <p>Il test è diviso in <strong>livelli</strong>, in alcuni sarà <strong>simulato un attacco informatico</strong>.</p>
    //             <p>Dovrai rispondere a delle domande e <strong>segnalare</strong> quando ti accorgi che c’è un attacco.</p>
    //             <p>Completando con successo i livelli accumulerai punti e scalerai una <strong>classifica</strong> (anonimizzata).</p>
    //             <br />
    //             <p><strong>Vediamo ora come funziona un livello</strong></p>

    //             <Button variant="contained" onClick={() => setStatus("level1")} color="secondary">PROSEGUI</Button>
    //         </section>
    //     </>
    // )
}

function Level1({ type, setStatus }) {
    const [lvlStatus, setLvlStatus] = useState("question1")
    const [answare1, setAnsware1] = useState(null)
    const [answare2, setAnsware2] = useState(null)

    if(lvlStatus === "question1") {
        return <Question1 type={type} setStatus={setLvlStatus} answare={answare1} setAnsware={setAnsware1} />
    } else if(lvlStatus === "question2") {
        return <Question2 type={type} setStatus={setLvlStatus} answare={answare2} setAnsware={setAnsware2} />
    } else if(lvlStatus === "summary") {
        return <SummaryView 
                        key={lvlStatus}
                        test_type={type}
                        summary_type="summary"
                        answares={[
                            { value: answare1, highlighted: "#008F1D" },
                            { value: /* "5 + 12 = "  + */ answare2, highlighted: "#008F1D" },
                        ]} 

                        allow_info={false}

                        encoding_type={type}
                        encoding_content={sampleEncoding1(type, true)}
                        enable_left_highlighted="#008F1D"
                        enable_right_highlighted="#008F1D"
                        
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>Per l’ultima volta controlla che i set di { type === 'emojis' ? 'emoji' : 'vocaboli' } in alto (sia a sinistra che a destra) <strong>non siano cambiati</strong>.</p>
                                <p><u>Se uno dei due fosse <strong>diverso</strong> bisognerebbe <strong>segnalare un attacco</strong></u>.</p>
                                <p>Controlla tutto e poi premi <strong>TERMINA LIVELLO</strong></p>
                            </>,
                            <>
                                <p>A fine livello c’è un riepilogo con tutte le risposte.</p>
                                <p><strong>Controlla che entrambe siano corrette</strong>.</p>
                                <p><u>Se vedi risposte <strong>diverse</strong> bisogna <strong>segnalare un attacco</strong></u>.</p>
                            </>
                        ].reverse()}

                        allow_report={false}
                        highlighted_submit="yellow"
                        allow_submit={true}
                        
                        onSubmit={() => setStatus("level2")}
                        />
    }
}

function Question1({ type, setStatus, answare, setAnsware }) {
    const [subStatus, setSubStatus] = useState("question")
    const [selected, setSelected] = useState(null)

    if(subStatus === "question" || subStatus === "checked") {
        return <QuestionView 
                        key={subStatus}
                        test_type={type}
                        question_type="image"
                        question="Quale fiore preferisci tra questi?"
                        answares={[
                            { value: "margherita", answare: "Margherita", path: "margherita.png", highlighted: (subStatus === 'question' ? "yellow" : false) },
                            { value: "tulipano", answare: "Tulipano", path: "tulipano.png", highlighted: (subStatus === 'question' ? "yellow" : false) }
                        ]}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>In ogni livello ti verrà chiesta una preferenza.</p>
                                <p>Rispondi liberamente e poi premi su <strong>PROSEGUI</strong>.</p>
                                <p><u>Dopo aver premuto su <strong>PROSEGUI</strong> non potrai più tornare indietro a modificare la risposta</u>.</p>
                            </>
                        ].reverse()}

                        default_state={subStatus !== 'checked' ? 0 : 10}

                        allow_info={false}

                        selected={selected}
                        enable_tutorial_button={true}
                        onSelectionChange={a => {
                            setSelected(a)
                            setSubStatus("checked")} 
                        }
                        highlighted_submit={subStatus === 'checked' ? 'yellow' : false}
                        allow_report={false}
                        onSubmit={ans => {
                            setAnsware(ans)
                            setSubStatus("confirmation")}
                        }/>
    } else if(subStatus === "confirmation") {
        return <SummaryView 
                        key={subStatus}
                        test_type={type}
                        summary_type="confirmation"
                        answares={[{ value: answare, highlighted: "#008F1D" }]} 

                        encoding_type={type}
                        encoding_content={sampleEncoding1(type)}
                        enable_left_highlighted="#008F1D"

                        allow_info={false}
                        
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>Con la conferma preferenza appare in alto a sinistra un set di { type === 'emojis' ? 'emoji' : 'vocaboli' } casuali.</p>
                                <p>Fa parte di un meccanismo di <strong>sicurezza</strong> per aiutarti a rilevare gli attacchi: <u>se questo set <strong>cambia</strong> prima della fine del livello, bisogna segnalare un attacco</u>.</p>
                                <p>Osserva bene poi premi </p>
                                <p><strong>PROSEGUI</strong></p>
                            </>,
                            <>
                                <p>Proseguendo si va alla schermata di conferma preferenza:</p>
                                <p><strong>Controlla che la risposta mostrata sia quella che hai scelto</strong>.</p>
                                <p><u>Se fosse <strong>diversa</strong> bisognerebbe premere il bottone</u> <strong>Segnala un attacco</strong>.</p>
                            </>
                        ].reverse()}

                        allow_report={false}
                        highlighted_submit="yellow"
                        allow_submit={true}
                        
                        onSubmit={() => setStatus("question2")}
                        />
        }
}

function Question2({ type, setStatus, answare, setAnsware }) {
    const [counter, setCounter] = useState(0)
    const [subStatus, setSubStatus] = useState("question")
    const [selected, setSelected] = useState(null)

    if(subStatus === "question" || subStatus === "checked" || subStatus === "checked_wrong") {
        let overlayContent = [
            <>
                <p>Ricordati di controllare che il set di { type === 'emojis' ? 'emoji' : 'vocaboli' } <strong>non sia cambiato</strong></p>
            </>,
            <>
                <p>Dal <strong>terzo</strong> livello, oltre alla preferenza ti sarà chiesto il risultato di una <strong>somma</strong>.</p>
                <p>Seleziona la <strong>risposta corretta</strong> e poi <strong>PROSEGUI</strong> (anche qui <u>non potrai più tornare indietro</u>).</p>
                <p><u>Se <strong>non ci fosse la risposta esatta</strong> bisognerebbe <strong>segnalare un attacco</strong></u>.</p>
            </>
        ].reverse()

        if(subStatus === "checked_wrong") {
            overlayContent = [
                <>
                    <p>Controlla bene!</p>
                    <p>5 + 12 = 17</p>
                </>
            ]
        }

        return <QuestionView 
                        key={[counter, subStatus]}
                        test_type={type}
                        question_type="text"
                        question="Quanto fa 5 + 12?"
                        answares={[
                            { value: "16", answare: "16"},
                            { value: "19", answare: "19"},
                            { value: "17", answare: "17", highlighted: ((subStatus === 'question' || subStatus === "checked_wrong") ? "yellow" : false)},
                            { value: "18", answare: "18"},
                        ]}

                        allow_info={false}

                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={overlayContent}
                        
                        default_state={subStatus !== 'checked' ? 0 : 10}

                        encoding_type={type}
                        encoding_content={sampleEncoding1(type)}
                        enable_left_highlighted="#008F1D"

                        selected={selected}
                        enable_tutorial_button={true}
                        onSelectionChange={a => {
                            setSelected(a)
                            setSubStatus("checked")
                        }}
                        highlighted_submit={subStatus === 'checked' ? 'yellow' : false}
                        allow_report={false}
                        onSubmit={ans => {
                            if(ans !== "17") {
                                setSubStatus("checked_wrong")
                                setCounter(i => i + 1)
                            } else {
                                setAnsware(ans)
                                setSubStatus("confirmation")
                            }
                        }}/>
    } else if(subStatus === "confirmation") {
        return <SummaryView 
                        key={subStatus}
                        test_type={type}
                        summary_type="confirmation"
                        answares={[{ value: answare, highlighted: "#008F1D" }]} 

                        encoding_type={type}
                        encoding_content={sampleEncoding1(type, true)}
                        enable_left_highlighted="#008F1D"
                        enable_right_highlighted="#008F1D"

                        allow_info={false}
                        
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>Ricordati di controllare anche che il primo set di { type === 'emojis' ? 'emoji' : 'vocaboli' } (a sinistra) <strong>non sia cambiato</strong>.</p>
                                <p>Controlla tutto e poi <strong><em>PROSEGUI</em></strong></p>
                            </>,
                            <>
                                <p>Con la conferma della somma appare in alto a <strong>destra</strong> un <strong>secondo</strong> set di { type === 'emojis' ? 'emoji' : 'vocaboli' } casuali.</p>
                                <p>Anche questo serve per rilevare attacchi, <u>se <strong>cambia</strong> prima della fine del livello, bisogna <strong>segnalare un attacco</strong></u>.</p>
                            </>,
                            <>
                                <p>Anche per la somma c’è una schermata di conferma. <strong>Controlla anche questa risposta</strong>.</p>
                                <p><u>Se vedi una risposta <strong>diversa</strong> bisogna <strong>segnalare un attacco</strong></u>.</p>
                            </>
                        ].reverse()}

                        allow_report={false}
                        highlighted_submit="yellow"
                        allow_submit={true}
                        
                        onSubmit={() => setStatus("summary")}
                        />
        }

}

function Level2({ type, setStatus }) {
    const [lvlStatus, setLvlStatus] = useState("lvl_intro")
    const [selected, setSelected] = useState(false)

    if(lvlStatus === "lvl_intro") {
        return <LevelRankView 
                        key={lvlStatus}
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>Terminato un livello viene mostrata una pagina di progresso.</p>
                                <p>Il test ha 6 livelli obbligatori (minimo), poi ci sono livelli bonus opzionali.</p>
                                <p>Finiti i livelli dovrai compilare un <strong>questionario finale</strong> che chiederà quanti e quali <strong>attacchi</strong> hai riscontrato e segnalato.</p>
                                <p>Ora premi <strong>INIZIA LIVELLO 2</strong> del tutorial: vedremo un esempio di attacco.</p>
                            </>
                        ].reverse()}

                        enable_rank={false}
                        description={
                            <>
                                <p>Complimenti, hai completato il primo livello del Tutorial!</p>
                                <p>Premi il pulsante per continuare.</p>
                            </>
                        }
                        progress={1}
                        submit_text="INIZIA LIVELLO 2 DEL TUTORIAL"
                        allow_submit={true}

                        allow_info={false}

                        onSubmit={() => setLvlStatus("question")}
                        />
    } else if(lvlStatus === "question") {
        return <QuestionView 
                        key={lvlStatus}
                        test_type={type}
                        question_type="text"
                        question="Quale forma preferisci tra queste?"
                        highlight_question="#008F1D"
                        answares={[
                            { value: "cerchio", answare: "Cerchio", highlighted: (!selected ? "yellow" : false)},
                            { value: "triangolo", answare: "Triangolo", highlighted: (!selected ? "yellow" : false)},
                            { value: "quadrato", answare: "Quadrato", highlighted: (!selected ? "yellow" : false)},
                            { value: "Esagono", answare: "Esagono", highlighted: (!selected ? "yellow" : false)},
                        ]}
                        allow_info={false}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>Qui abbiamo il secondo tipo di preferenza: quattro opzioni senza immagini.</p>
                                <p>Controlla che le <strong>opzioni di risposta siano coerenti con la domanda</strong>, <u>altrimenti bisogna <strong>segnalare un attacco</strong></u>.</p>
                                <p>Rispondi liberamente e poi</p>
                                <p><strong>PROSEGUI</strong></p>
                            </>
                        ].reverse()}

                        encoding_type={type}
                        encoding_content={sampleEncoding2(type)}
                        enable_left_highlighted="#008F1D"

                        enable_tutorial_button={true}
                        onSelectionChange={() => setSelected(true)}
                        highlighted_submit={selected ? 'yellow' : false}
                        allow_report={false}
                        onSubmit={ans => {
                            setSelected(ans)
                            setLvlStatus("confirmation")}
                        }/>
    } else if(lvlStatus === "confirmation") {
        return <SummaryView 
                        key={lvlStatus}
                        test_type={type}
                        summary_type="confirmation"
                        answares={[{ value: selected, highlighted: "#008F1D" }]} 

                        encoding_type={type}
                        encoding_content={sampleEncoding2(type)}
                        enable_left_highlighted="#008F1D"
                        
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>Come prima, <strong>controlla la risposta mostrata e osserva</strong> il set di { type === 'emojis' ? 'emoji' : 'vocaboli' } in alto a sinistra.</p>
                                <p><u>È normale che sia diverso dal livello precedente: ogni livello ha i suoi set</u>.</p>
                                <p>Osserva e poi</p>
                                <p><strong>PROSEGUI</strong></p>
                            </>
                        ].reverse()}

                        allow_info={false}

                        allow_report={false}
                        highlighted_submit="yellow"
                        allow_submit={true}
                        
                        onSubmit={() => setLvlStatus("summary")}
                        />
    } else if(lvlStatus === "summary") {
        return <SummaryView 
                        key={lvlStatus}
                        test_type={type}
                        summary_type="summary"
                        answares={[
                            { value: selected, highlighted: "#008F1D" },
                        ]} 

                        encoding_type={type}
                        encoding_content={sampleEncoding2(type, true)}
                        enable_left_highlighted="#FB4437"

                        allow_info={false}
                        
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>Stavolta <u><strong>il set di { type === 'emojis' ? 'emoji' : 'vocaboli' } è cambiato</strong></u> prima della fine del livello!</p>
                                <p>Dunque c’è un attacco in corso, premi</p>
                                <p><strong>Segnala un attacco</strong></p>
                            </>,
                            <>
                                <p>I primi 2 livelli non hanno la somma, quindi dalla conferma preferenza si va direttamente al riepilogo livello.</p>
                                <br />
                                <p><u>Controlla <strong>la risposta mostrata</strong> e che <strong>il set di { type === 'emojis' ? 'emoji' : 'vocaboli' } non sia cambiato</strong></u>.</p>
                            </>
                        ].reverse()}

                        allow_report={true}                        
                        allow_submit={false}
                        highlighted_report="yellow"
                        autoopen_report={false}

                        onReportState={() => setLvlStatus("summary_report")}
                        
                        />
    } else if(lvlStatus === "summary_report") {
        return <SummaryView 
                        key={lvlStatus}
                        test_type={type}
                        summary_type="summary"
                        answares={[
                            { value: selected, highlighted: "#008F1D" },
                        ]} 
                        encoding_type={type}
                        encoding_content={sampleEncoding2(type, true)}
                        enable_left_highlighted="#FB4437"
                        enable_tutorial_button={true}
                        enable_tutorial_overlay={true}
                        tutorial_overlay_content={[
                            <>
                                <p>Avviando la segnalazione si apre una finestra che <strong>descrive come si possono manifestare gli attacchi informatici da segnalare</strong>.</p>
                                <p>Questo esempio rientra nell’ultima categoria: <strong>il meccanismo di sicurezza ha rilevato un attacco poiché un set di { type === 'emojis' ? 'emoji' : 'vocaboli' } è cambiato</strong>.</p>
                                <p>Premi <strong>Segnala un attacco</strong> per confermare la segnalazione e concludere il livello.</p>
                            </>
                        ].reverse()}
                        allow_report={true}                        
                        allow_submit={false}
                        highlighted_report="yellow"
                        autoopen_report={true}
                        allow_info={false}
                        onReportState={st => {
                            if(st === false) {
                                setLvlStatus("summary")
                            }
                        }}
                        onReport={() => setStatus("level3")}
                        />
    }

}

function Level3({ setStatus }) {
    const [subStatus, setSubStatus] = useState("none")
    const [counter, setCounter] = useState(0)

    let content = null
    switch(subStatus) {
        case "none":
            content = <>
                <p>Segnalando l’attacco si completa il livello e si passa alla schermata di progresso.</p>
                <p><strong>Come esempio</strong> ti mostriamo un fac-simile della schermata che ti verrà mostrata <u>quando completerai i 6 livelli obbligatori</u>.</p>
                <p>Una classifica anonima ti confronterà con gli altri. Per salire dovrai completare correttamente dei livelli bonus.</p>
                <p><strong>Esplora ora i vari pulsanti</strong>, premendoli ti <strong>spiegheremo  come funzioneranno</strong> nel test.</p><br /><p>Quando hai finito premi</p>
                <p><strong>TERMINA TUTORIAL E INIZIA IL TEST</strong></p>
            </>
            break

        case "survey":
            content = <>
                <p>Il pulsante </p>
                <p><strong>Termina Test e vai al Sondaggio</strong></p>
                <p>andrà premuto quando non vorrai fare altri livelli bonus (o li avrai terminati).</p>
                <p>Ti chiederemo quante e quali anomalie (attacchi) hai riscontrato.</p>
                <p><u>Non potrai tornare indietro a fare altri livelli bonus.</u></p>
                <p>Quando hai finito di esplorare premi</p>
                <p><strong>TERMINA TUTORIAL E INIZIA IL TEST</strong></p>
            </>
            break

        case "submit":
            content = <>
                <p>Dopo aver completato i 6 livelli obbligatori, potrai fare dei livelli bonus (fino a 10 bonus, per un totale di massimo 16 livelli in tutto).</p>
                <p>Coi livelli bonus potrai accumulare punti extra e scalare la classifica.</p>
                <p><u>Per ottenere punti dovrai risolvere correttamente le somme e segnalare subito ogni attacco</u>.</p>
                <p>Quando hai finito di esplorare premi</p>
                <p><strong>TERMINA TUTORIAL E INIZIA IL TEST</strong></p>
            </>
            break

        case "info_open":
        case "info_close":
            content = <>
                <p>In ogni momento durante il test, premendo su <strong>AIUTO</strong> si aprirà una finestra che riporta un riassunto di tutte le istruzioni.</p>
                <p>Quando hai finito di esplorare premi</p>
                <p><strong>TERMINA TUTORIAL E INIZIA IL TEST</strong></p>
            </>
            break
    }

    return <LevelRankView 
                    key={counter}
                    enable_tutorial_finish={true}
                    enable_tutorial_button={true}
                    enable_tutorial_overlay={true}
                    tutorial_overlay_content={[content].reverse()}
                    default_state={subStatus === "info_close" ? 1 : 0}

                    enable_rank={true}
                    data={[
                        [1, "AXY123", 1500 ],
                        [2, "RTE864", 1200 ],
                        [3, "SIR638", 1000 ],
                        [18, "YOU PSE856", 600 ],
                    ]}
                    description={
                        <>
                            <p>Complimenti, hai completato i 6 livelli obbligatori!</p>
                            
                            <p>
                                Se vuoi puoi continuare con i livelli bonus (fino ad un massimo di 10 livelli): completandoli correttamente 
                                potrai accumulare più punti ed entrare in classifica, attualmente sei al 18° posto:
                            </p>
                        </>
                    }
                    progress={1}
                    submit_text="LIVELLO BONUS 1"
                    allow_submit={true}
                    enable_survey={true}

                    highlight_survey="yellow"
                    highlighted_info="yellow"
                    highlighted_submit="yellow"

                    autoopen_info={subStatus === "info_open"}

                    onSurvey={() => { 
                        setCounter(i => i + 1)
                        setSubStatus("survey")
                    }}
                    onSubmit={() => {
                        setCounter(i => i + 1)
                        setSubStatus("submit")
                    }}
                    onInfoState={() => {
                        setCounter(i => i + 1)
                        if(subStatus === 'info_open') {
                            setSubStatus("info_close")
                        } else {
                            setSubStatus("info_open")
                        }
                    }}
                    onFinishTutorial={() => setStatus("finished")}
                    />
}

export default function Info9({ type, setQuestionStatus }) {
    useEffect(() => { fetchAPI("/questions/info/9/show") })

    const [status, setStatus] = useState("intro")

    switch(status) {
        case "intro":
            return <Intro setStatus={setStatus} />

        case "level1":
            return <Level1 type={type} setStatus={setStatus} />

        case "level2":
            return <Level2 type={type} setStatus={setStatus} />

        case "level3":
            return <Level3 setStatus={setStatus} />

        case "finished":
            fetchAPI('/questions/info/start')
                    .then(() => setQuestionStatus('level_info'))
            return <p>Caricamento...</p>

        default: 
            return <p>Finished :D</p>
    }

}