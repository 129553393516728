import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';

export default function TutorialInfoButton({ onClick }) {
    return (
        <IconButton aria-label="delete" size="large" onClick={onClick} color='secondary'>
            <InfoIcon fontSize="inherit" />
        </IconButton>
    )
}
