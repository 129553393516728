import { Grid } from "@mui/material";
import LevelView from "./LevelView";
import TutorialBox from "./TutorialBox";

export default function SummaryView({
    // encoding
    encoding_type = "none", 
    encoding_content = null, 
    enable_left_highlighted = false, 
    enable_right_highlighted = false,

    // tutorial
    enable_tutorial_button = false, 
    enable_tutorial_overlay = false, 
    tutorial_overlay_content = null,
    onOverlayOk = () => {},

    // type
    test_type=null, summary_type=null, answares,
    confirmation_type = "preference",

    // report
    enable_report = true, 
    highlighted_report = false, 
    onReport = () => {}, 
    onReportState = () => {}, 
    allow_report = true,
    autoopen_report = false,

    // submit
    enable_submit = true,
    highlighted_submit = false,
    submit_text = null,
    onSubmit = _ => {},
    allow_submit = null,

    // info
    enable_info = true,
    highlighted_info = false,
    onInfoState = () => {},
    allow_info = true,

    // steps
    enable_steps = false,
    steps_current = 0,
    steps_total = 1,

    allow_survey = true,

}) {

    return (
        <LevelView
                encoding_type={encoding_type}
                encoding_content={encoding_content}
                enable_left_highlighted={enable_left_highlighted}
                enable_right_highlighted={enable_right_highlighted}

                enable_tutorial_button={enable_tutorial_button}
                enable_tutorial_overlay={enable_tutorial_overlay}
                tutorial_overlay_content={tutorial_overlay_content}
                onOverlayOk={onOverlayOk}

                type={test_type}

                enable_report={enable_report}
                highlighted_report={highlighted_report}
                onReport={onReport}
                onReportState={onReportState}
                allow_report={allow_report}
                autoopen_report={autoopen_report}

                enable_submit={enable_submit}
                highlighted_submit={highlighted_submit}
                submit_text={submit_text !== null ? submit_text : (summary_type === "confirmation" ? "PROSEGUI" : "TERMINA LIVELLO")}
                onSubmit={onSubmit}
                allow_submit={allow_submit}

                enable_info={enable_info}
                highlighted_info={highlighted_info}
                onInfoState={onInfoState}

                allow_survey={allow_survey}
                allow_info={allow_info}

                enable_steps={enable_steps}
                steps_current={steps_current}
                steps_total={steps_total}>
            
            <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}>
                    
                <Grid item className="questionTitle">{ summary_type === 'confirmation' ? (confirmation_type === "preference" ? "Conferma preferenza" : "Conferma somma") : "Riepilogo livello" }</Grid>
                { answares.map(e => 
                    <Grid item>
                        <TutorialBox enable={e.highlighted}>
                            <p>{ e.value }</p>
                        </TutorialBox>
                    </Grid>) }
            </Grid>
        </LevelView>
    )
}