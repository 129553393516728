import { useEffect, useState } from "react";
import fetchAPI from "../utils/Fetcher";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Progress from "../components/Progress";
import FurtherInformation from "../components/FurtherInformation";
import Overlay from "../components/Overlay";
import LevelInfoOverlay from "./LevelInfoOverlay";
import LevelRankView from "../views/LevelRankView";

export default function LevelInfo({ type, level, setQuestionStatus, setLevel, setQuestion }) {
    const [data, setData] = useState(null)

    useEffect(() => {
        fetchAPI(`/questions/level/${level}/show`)
            .then(json => setData(json)) }, [])

    if(data === null) {
        return <>Caricamento...</>
    }


    function genDescription(num) {
        return <p>Complimenti, hai completato il {num} livello! <br /> Premi il pulsante per continuare</p>
    }

    let description = "";
    switch(level) {
        case 1:
            description = <>
                <p>Siamo pronti? <br/> Iniziamo!</p>
                <p>Il test è composto da 6 livelli, premi il pulsante per iniziare.</p>
            </>
            break

        case 2:
            description = genDescription('primo')
            break 

        case 3:
            description = genDescription('secondo')
            break 

        case 4:
            description = genDescription('terzo')
            break 

        case 5:
            description = genDescription('quarto')
            break 

        case 6:
            description = genDescription('quinto')
            break

        case null:
            description = (
                <>
                    <p>
                        Complimenti, hai completato tutti i livelli bonus! <br />
                        Alla fine hai raggiunto il { data.position }° posto in classifica, ben fatto!
                    </p>
                </>
            )
            break
        
        default:
            description = (
                <>
                    <p>
                        {
                            level == 6 ?
                            <>Complimenti, hai completato i 6 livelli obbligatori!</> :
                            <>Complimenti, hai completato {level - 1} livelli!</>
                        }<br />
                        Se vuoi puoi continuare con i livelli bonus (fino ad un massimo di 10 livelli): completandoli correttamente potrai accumulare più
                            punti e scalare la classifica, attualmente sei al { data.position }° posto
                    </p>
                </>
            )
            break
    }

    return <LevelRankView
                submit_text={level <= 6 ? "INIZIA LIVELLO " + level : "LIVELLO BONUS " + (level - 6)}
                enable_rank={level == null || level > 6}
                data={data.ranking}
                description={description}

                enable_submit={level != null}
                enable_survey={level == null || level > 6}

                allow_submit={true}
                progress={level} 

                onInfoState={s => fetchAPI(`/questions/level/${level}/` + (s ? "info" : "close_info"))}

                onSubmit={() => fetchAPI(`/questions/level/${level}/start`)
                    .then(() => {
                        setQuestion(1)
                        setQuestionStatus('question')
                    })
                }

                onSurvey={() => {
                    if(level === null)
                        setQuestionStatus('finished')
                    else
                        fetchAPI(`/questions/level/${level}/quit`)
                        .then(() => setQuestionStatus('finished'))
                    }
                }
                />


    // const [data, setData] = useState(null)
    // const [overlay, setOverlay] = useState(false)

    // useEffect(() => {
    //     fetchAPI(`/questions/level/${level}/show`)
    //         .then(json => setData(json)) }, [])

    // if(data === null) {
    //     return <>Caricamento...</>
    // }

    // let description = <></>;

    // function RankTable({ data }) {
    //     // console.log(rank)

    //     return (
    //         <TableContainer component={Paper}>
    //             <Table size="small">
    //                 <TableHead>
    //                     <TableRow>
    //                         <TableCell align="center">pos</TableCell>
    //                         <TableCell align="center">utente</TableCell>
    //                         <TableCell align="center">pti</TableCell>
    //                     </TableRow>
    //                 </TableHead>
    //                 <TableBody>
    //                     {
    //                         data.map(e => (
    //                             e[1].includes("YOU ")
    //                             ?
    //                             <TableRow>
    //                                 <TableCell align="center">
    //                                     <strong>{e[0]}°</strong>
    //                                 </TableCell>
    //                                 <TableCell align="center">
    //                                     <strong>{ ">> " + e[1].replace("YOU ", "") + " <<" }</strong>
    //                                 </TableCell>
    //                                 <TableCell align="center">
    //                                     <strong>{ e[2] }</strong>
    //                                 </TableCell>
    //                             </TableRow>
    //                             :
    //                             <TableRow align="center">
    //                                 <TableCell align="center">
    //                                     {e[0]}°
    //                                 </TableCell>
    //                                 <TableCell align="center">
    //                                     { e[1].replace("YOU ", "") }
    //                                 </TableCell>
    //                                 <TableCell align="center">
    //                                     { e[2] }
    //                                 </TableCell>
    //                             </TableRow>
    //                         ))
    //                     }
    //                 </TableBody>
    //             </Table>
    //         </TableContainer>
    //     )
    // }

    // function genDescription(num) {
    //     return <p>Complimenti, hai completato il {num} livello! <br /> Premi il pulsante per continuare</p>
    // }

    // switch(level) {
    //     case 1:
    //         description = <p>Il test è composto da 6 livelli, premi il pulsante per iniziare</p>
    //         break

    //     case 2:
    //         description = genDescription('primo')
    //         break 

    //     case 3:
    //         description = genDescription('secondo')
    //         break 

    //     case 4:
    //         description = genDescription('terzo')
    //         break 

    //     case 5:
    //         description = genDescription('quarto')
    //         break 

    //     case 6:
    //         description = genDescription('quinto')
    //         break

    //     case null:
    //         description = (
    //             <>
    //                 <p>
    //                     Complimenti, hai completato tutti i livelli bonus! <br />
    //                     Alla fine hai raggiunto il { data.position }° posto in classifica, ben fatto!
    //                 </p>

    //                 <RankTable data={data['ranking']} />
    //             </>
    //         )
    //         break
        
    //     default:
    //         description = (
    //             <>
    //                 <p>
    //                     {
    //                         level == 6 ?
    //                         <>Complimenti, hai completato i 6 livelli obbligatori!</> :
    //                         <>Complimenti, hai completato {level - 1} livelli!</>
    //                     }<br />
    //                     Se vuoi puoi continuare con i livelli bonus (fino ad un massimo di 10 livelli): completandoli correttamente potrai accumulare più
    //                         punti e scalare la classifica, attualmente sei al { data.position }° posto
    //                 </p>

    //                 <RankTable data={data['ranking']} />
    //             </>
    //         )
    //         break
    // }

    // return (
    //     <>
    //         <Grid item>
    //             <Typography variant="h2" className="mainTitle">
    //                 Progresso
    //             </Typography>
    //         </Grid>

    //         <Grid item style={{minHeight: '50svh'}}>
    //             {description}
    //         </Grid>

    //         <Grid item>
    //             <Progress done={level === null ? 10 : level - 1} />
    //         </Grid>

    //         <Grid item>
    //             {
    //                 (level === null || level > 6) &&
    //                     <Button variant={level === null ? "contained" : "outlined"} onClick={() => {
    //                         if(level === null)
    //                             setQuestionStatus('finished')
    //                         else
    //                             fetchAPI(`/questions/level/${level}/quit`)
    //                             .then(() => {
    //                                 setQuestionStatus('finished')
    //                             }
    //                     )}
    //                     }>VAI AL SONDAGGIO</Button>
    //             }

    //             &nbsp;&nbsp;&nbsp;

    //             {
    //                 level !== null &&
    //                     <Button variant="contained" onClick={() =>
    //                         fetchAPI(`/questions/level/${level}/start`)
    //                             .then(() => {
    //                                 setQuestion(1)
    //                                 setQuestionStatus('question')
    //                             })

    //                     }>INIZIA LIVELLO { level }</Button>
    //             }

                
    //         </Grid>

    //         <Grid item>
    //             <FurtherInformation onClick={() => 
    //                 fetchAPI(`/questions/level/${level}/info`)
    //                     .then(() => setOverlay(true)) } />
    //         </Grid>

    //         <LevelInfoOverlay type={type} open={overlay} onClose={() => fetchAPI(`/questions/level/${level}/close_info`)
    //                     .then(() => setOverlay(false))} />
    //     </>
    // )
}