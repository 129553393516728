import { useState } from "react";
import EcnodingView from "./EncodingView";
import TutorialInfoOverlay from "./TutorialInfoOverlay";
import TutorialInfoButton from "./TutorialInfoButton";
import ButtonsViews from "./ButtonsView";
import LevelInfoOverlay from "../questions/LevelInfoOverlay";
import ReportOverlay from "../questions/ReportOverlay";
import { Button } from "@mui/material";

export default function LevelView({
    enable_tutorial_finish = false,
    onFinishTutorial = () => {},

    // encoding
    enable_encoding = true,
    encoding_type = "none", 
    encoding_content = null, 
    enable_left_highlighted = false, 
    enable_right_highlighted = false,

    // tutorial
    enable_tutorial_button = false, 
    enable_tutorial_overlay = false, 
    tutorial_overlay_content = null,
    onOverlayOk = () => {},

    // type
    type, children,

    // report
    enable_report = true, 
    highlighted_report = false, 
    onReport = () => {}, 
    onReportState = () => {}, 
    allow_report = true,
    autoopen_report = false,

    // submit
    enable_submit = true,
    highlighted_submit = false,
    submit_text = "PROSEGUI",
    onSubmit = _ => {},
    allow_submit = null,

    // info
    enable_info = true,
    highlighted_info = false,
    onInfoState = () => {},
    autoopen_info = false,
    allow_info = true, 

    // survey
    enable_survey = false,
    highlight_survey = false,
    onSurvey = () => {},
    allow_survey = true,

    // steps
    enable_steps = false,
    steps_current = 0,
    steps_total = 1,

    default_state = 0,


}) {
    
    const [overlayStatus, setOverlayStatus] = useState(default_state)
    const [showInfoOverlay, setShowInfoOverlay] = useState(false)
    const [showReportOverlay, setShowReportOverlay] = useState(false)

    let result = null
    let overlay = enable_tutorial_overlay && overlayStatus < tutorial_overlay_content.length

    if(overlay) {
        result = (
            <TutorialInfoOverlay showed={overlay}
                last={enable_tutorial_overlay && overlayStatus == tutorial_overlay_content.length - 1}
                onClickOk={() => {
                    setOverlayStatus(e => e + 1)
                    onOverlayOk()
                    if(autoopen_report && overlayStatus < tutorial_overlay_content.length) {
                        setShowReportOverlay(true)
                    }
                }}>
                {overlay && tutorial_overlay_content[overlayStatus]}
            </TutorialInfoOverlay>
        )
    } else {
        result = (
            <>
                { enable_encoding  &&
                    <header>
                        

                        <EcnodingView type={encoding_type} content={encoding_content}
                            enable_left_highlighted={enable_left_highlighted}
                            enable_right_highlighted={enable_right_highlighted} />
                    </header> 
                }
            
                <section>
                    { enable_tutorial_button && 
                        <TutorialInfoButton onClick={() => setOverlayStatus(0)} /> }

                    { children }
                </section>

                <footer>
                    <ButtonsViews
                        enable_report={enable_report} 
                        highlight_report={highlighted_report} 
                        onReport={() => {
                            setShowReportOverlay(true)
                            onReportState(true)
                        }} 
                        allow_report={allow_report}

                        enable_submit={enable_submit} 
                        highlight_submit={highlighted_submit} 
                        submit_text={submit_text} 
                        onSubmit={onSubmit} 
                        allow_submit={allow_submit}

                        enable_info={enable_info} 
                        highlight_info={highlighted_info}
                        onInfo={() => {
                            onInfoState(true)
                            setShowInfoOverlay(true)
                        }}

                        enable_steps={enable_steps} 
                        steps_current={steps_current} 
                        steps_total={steps_total}

                        enable_survey={enable_survey}
                        highlight_survey={highlight_survey}
                        onSurvey={onSurvey}
                        allow_survey={allow_survey}

                        allow_info={allow_info}
                        /> 

                    {
                        enable_tutorial_finish &&
                        <Button variant="contained" color="secondary" onClick={onFinishTutorial}>TERMINA TUTORIAL E INIZIA IL TEST</Button>
                    }
                </footer>

                <LevelInfoOverlay
                    type={type}
                    open={autoopen_info || showInfoOverlay}
                    onClose={() => {
                        onInfoState(false)
                        setShowInfoOverlay(false)
                    }} />

                <ReportOverlay
                    type={type}
                    open={autoopen_report || showReportOverlay}
                    highlighted_report={highlighted_report}
                    onClose={() => {
                        onReportState(false)
                        setShowReportOverlay(false)
                    }}
                    onReport={onReport} />
            </>
        )
    }

    let cl = "mainPanel"
    if(enable_tutorial_button) {
        if(overlay) {
            cl += " overlayGreen"
        } else {
            cl += " tutorialMainPanel"
        }
    } else {
        cl += " nonTutorialMainPanel"
    }

    return (
        <span className={cl}>
            { result }
        </span>
    )

}