import { Grid } from "@mui/material";
import TextQuestion from "../components/TextQuestion";
import fetchAPI from "../utils/Fetcher";
import Dots from "../components/Dots";
import QuestionView from "../views/QuestionView";

export default function Reason({ setSurveyStatus }) {
    return <QuestionView
                    enable_encoding={false}

                    question_type="text"
                    question="Per quale motivo non hai segnalato i problemi riscontrati?"
                    answares={[
                        { value: 'Non lo reputavo importante', answare: 'Non lo reputavo importante'} ,
                        { value: 'Non avevo voglia', answare: 'Non avevo voglia'} ,
                        { value: 'Non mi interessava', answare: 'Non mi interessava'} ,
                        { value: 'Errore/incomprensione', answare: 'Errore/incomprensione'} ,
                    ]}

                    enable_report={false}
                    enable_info={false}

                    enable_steps={true}
                    steps_current={6}
                    steps_total={6}

                    onSubmit={a => {
                        fetchAPI('/survey/question/reason/submit', {}, { 'answare': a })
                            .then(() => setSurveyStatus('finished'))
                    }}

                    />


    // return (
    //     <>
    //         <TextQuestion question="Per quale motivo non hai segnalato i problemi riscontrati?"
    //                 answares={[
    //                     'Non lo reputavo importante',
    //                     'Non avevo voglia',
    //                     'Non mi interessava',
    //                     'Non ci ho pensato'
    //                 ]}
    //                 setAnsware={a => {
    //                     fetchAPI('/survey/question/reason/submit', {}, { 'answare': a })
    //                         .then(() => setSurveyStatus('finished'))
    //                 }} />

    //         <Grid item>
    //             <Dots index={6}
    //                 total={6} />
    //         </Grid>
    //     </>
    // )
}