import React, { useEffect, useState } from 'react';
import Main from './Main';
import fetchAPI from './utils/Fetcher';
import { createTheme, ThemeProvider } from '@mui/material';
import { green, yellow } from '@mui/material/colors';

// export const defaultContext = React.createContext(null);



export default function App() {
    const [reload, setReload] = useState(0);
    const [state, setState] = useState(null);
    // const [testCode, setTestCode] = useState(null);

    // if(testCode === null) {
    //     const queryString = window.location.search;
    //     const urlParams = new URLSearchParams(queryString);
    //     setTestCode(urlParams.get('test_id'))
    //     return <p>Caricamento...</p>;
    // }

    let gr = green
    gr.main = "#008F1D"


    const Theme = createTheme({
        typography: {
            button: {
              fontSize: '13pt',
              textTransform: 'none'
            },
        },
        palette: {
            main: "#0073E7",
            secondary: gr
            // secondary: green
        }
    });

    useEffect(() => { fetchAPI('/status', {}, null).then(s => setState(s)) }, [reload]);

    if(state !== null) 
        return (
            // <defaultContext.Provider value={{ testCode: testCode, setTestCode: setTestCode }}>
            <>
                <ThemeProvider theme={Theme}>
                    <Main state={state} />
                </ThemeProvider>
            </>
            // </defaultContext.Provider>
        );
    else
        return <p>Caricamento...</p>;
}

