import { Typography } from "@mui/material";

export default function Finished() {
    return (
        <>
        <Typography variant="h2" className="mainTitle">GRAZIE</Typography>

        <p>Hai completato il test.</p>
        <p>Grazie mille per averci aiutato.</p>
        <br />
        <p>Ora puoi chiudere la pagina.</p>
        </>
    )
}
